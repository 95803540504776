<template>
  <div class="radio-tabs">
    <div class="tabs" :style="tabCustomStyle">
      <div
        class="tab"
        :class="{ 'tab-active': activeIndex === index, 'tab-line': activeIndex !== index }"
        :style="[textStyle(index)]"
        v-for="(item, index) in tabs"
        :key="index"
        @click="tabClickHandler(index)"
        >{{ item.name }}</div
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'radioTabs',
    data() {
      return {
        activeIndex: this.defaultIndex
      };
    },
    props: {
      defaultIndex: {
        type: [String, Number],
        default: 0
      },
      tabs: {
        type: Array,
        default: () => []
      },
      activeStyle: {
        type: Object,
        default: () => {}
      },
      inactiveStyle: {
        type: Object,
        default: () => {}
      },
      tabCustomStyle: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      textStyle(index) {
        return (index) => {
          const customeStyle = index === this.activeIndex ? this.activeStyle : this.inactiveStyle;
          // if (this.list[index].disabled) {
          //   style.color = '#c8c9cc'
          // }
          return customeStyle;
        };
      }
    },
    methods: {
      // updateActiveData(value) {
      //   this.$emit('input', value);
      // },
      tabClickHandler(index) {
        // this.updateActiveData(index);
        this.activeIndex = index;
        this.$emit('tabClick', this.tabs[index]);
      }
    }
  };
</script>

<style lang="less" scoped>
  @radio: 12px;
  .radio-tabs {
    .tabs {
      height: 54px;
      border-radius: 8px 8px 0px 0px;
      background: #ededed;
      display: flex;
      padding: 10px 20px 0;
      color: #575757;
      .tab {
        height: 100%;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        .relative;
        .pointer;
      }
      .tab-line {
        .border-right-1px-color(1px,#FFFFFF,10px,10px);
      }
      .tab-active {
        border-radius: @radio @radio 0px 0px;
        background: #ffffff;

        &:before {
          content: '';
          position: absolute;
          width: @radio;
          height: @radio;
          left: -@radio;
          bottom: 0;
          background: radial-gradient(circle at 0 0, transparent @radio, #ffffff @radio);
        }
        &:after {
          content: '';
          position: absolute;
          width: @radio;
          height: @radio;
          right: -@radio;
          bottom: 0;
          background: radial-gradient(circle at 100% 0, transparent @radio, #ffffff @radio);
        }
      }
    }
  }
</style>
