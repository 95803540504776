<template>
  <div class="task-state">
    <div class="title">
      <i class="blue-block"></i>
      <span>任务状态</span>
      <div class="arbitration" v-if="info.is_dspute == 'y'">
        <i class="arbitration-icon"></i>
        <span class="arbitration-des">有仲裁</span>
      </div>
    </div>
    <div class="status">
      <span class="text">{{ title }}</span>
      <span class="count-down" v-if="info.status < 8 && !isTimeExpired">{{ countDown }}</span>
      <!--      <div class="com-btn" v-if="state == 9">去评论</div>-->
    </div>
    <div class="list">
      <div class="left">
        <div class="item" v-for="(item, index) in listConfig.left" :key="index">
          <span class="label">{{ item.label }}</span>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
      <div class="right">
        <div class="item" v-for="(item, index) in listConfig.right" :key="index">
          <span class="label">{{ item.label }}</span>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
      <div class="btn" @click="confirmHandle(buttonText)" v-if="buttonText">{{ buttonText }}</div>
    </div>
    <timeDialog
      :isShow="isShowStartTimePop"
      :task_id="info.id"
      type="task"
      @close="isShowStartTimePop = false"
    />
    <!--    <div class="rate" v-if="state == 9">-->
    <!--      <span class="label">综合评分：</span>-->
    <!--      <div class="value">-->
    <!--        <starList :total="'5.0'" />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
  import starList from '@/_components/StarList/src/StarList';
  import timeDialog from './timeDialog';
  export default {
    components: { starList, timeDialog },
    name: 'taskState',
    data() {
      return {
        countDown: '',
        isTimeExpired: false,
        map: {
          1: '封闭期',
          3: '招标中',
          4: '流标',
          5: '选标中',
          7: '等待开始' || '资金准备期',
          8: '工作中',
          9: '结束'
        },
        timer: null,
        isShowStartTimePop: false
      };
    },
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    watch: {
      info(val) {
        if (val.start_work_time) {
          this.timer = setInterval(() => {
            this.countDown = this.countDownHandle(val.start_work_time);
          }, 1000);
        }
      }
    },
    computed: {
      state() {
        return this.info.status;
      },
      listConfig() {
        let _list = { left: [], right: [] };
        if (this.state < 8) {
          _list.left = [
            {
              label: '计划雇佣：',
              value: `${this.info.plan_hire_count || 0}人`
            },
            {
              label: '参与投标：',
              value: `${this.info.bid_count || 0}人`
            },
            {
              label: '发出邀请：',
              value: `${this.info.invite_count || 0}人`
            }
          ];
          _list.right = [
            {
              label: '已雇佣：',
              value: `${this.info.bid_ok_count || 0}人`
            }
          ];
        }
        if (this.state == 8) {
          _list.left = [
            {
              label: '计划雇佣：',
              value: `${this.info.plan_hire_count || 0}人`
            }
          ];
          _list.right = [
            {
              label: '已雇佣：',
              value: `${this.info.bid_ok_count || 0}人`
            }
          ];
        }
        if (this.state == 9) {
          _list.left = [
            {
              label: '雇佣人数：',
              value: `${this.info.real_hire_count || 0}人`
            },
            {
              label: '任务历时：',
              value: `${this.info.duration_time || 0}天`
            }
          ];
          _list.right = [
            {
              label: '收到评价：',
              value: `${this.info.evaluate_count || 0}人`
            },
            {
              label: '评价雇员：',
              value: `${this.info.evaluate_freelaner_count || 0}人`
            }
          ];
        }
        return _list;
      },
      title() {
        let tit = this.map[this.state] || '状态异常';
        if (this.state === 8) {
          return `第${this.info.current_work_number || 1}/${this.info.periods.length}期 工作中`;
        }
        return tit;
      },
      buttonText() {
        // const time = this.info.bid_select_end_time || '';
        // const nowTime = new Date().getTime();
        // const bidEndTime = new Date(time).getTime();
        if (this.state < 7) {
          return '结束选标';
        }
        if (this.state == 7) {
          return '开始工作';
        }
        return '';
      }
    },
    methods: {
      async confirmHandle(type) {
        if (type === '结束选标') {
          const res = await this.$Http.post({
            url: '/api/uc/bid/endselect',
            params: {
              task_id: this.info.id
            }
          });

          if (res && res.statusCode === 200) {
            this.$notify({ title: '已结束选标', type: 'success' })
            location.reload();
          }
        }
        if (type === '开始工作') {
          // const { statusCode } = await this.$Http.post({
          //   url: '/api/uc/mini/task/start',
          //   params: {
          //     task_id: this.info.id
          //   }
          // });
          // statusCode === 200 &&
          //   this.$notify({ title: '已开始工作', type: 'success' }) &&
          //   setTimeout(() => {
          //     location.reload();
          //   }, 1500);

          this.isShowStartTimePop = true;
        }
      },
      countDownHandle(time) {
        const timeTran = new Date(time);
        const nowTime = +new Date();
        if (timeTran <= nowTime) {
          this.isTimeExpired = true;
          clearInterval(this.timer);
          // location.reload();
        }
        const times = (timeTran - nowTime) / 1000;
        const _d = parseInt((times / 24 / 60 / 60) % 24);
        const day = _d;
        const _h = parseInt((times / 60 / 60) % 24);
        const hour = _h < 10 ? '0' + _h : _h;
        const _m = parseInt((times / 60) % 24);
        const minute = _m < 10 ? '0' + _m : _m;
        const _s = parseInt(times % 60);
        const second = _s < 10 ? '0' + _s : _s;
        return `${day}天${hour}时${minute}分${second}秒后截止`;
      }
    },
    beforeDestroy() {
      clearInterval(this.timer);
    }
  };
</script>

<style lang="less" scoped>
  .task-state {
    background-color: #fff;
    padding: 30px 0;
    margin-left: 20px;
    width: 380px;
    .title {
      display: flex;
      align-items: center;
      padding: 0 30px;
      position: relative;
      .blue-block {
        display: inline-block;
        width: 8px;
        height: 24px;
        border-radius: 1px;
        background: #00b4ff;
      }
      & > span {
        font-size: 18px;
        line-height: 24px;
        color: #575757;
        margin: 0 16px 0 10px;
      }
      .arbitration {
        position: absolute;
        right: 40px;
        display: flex;
        align-items: center;
        &-icon {
          background: url('~@/assets/icons/_tmp/arbitration.png') no-repeat;
          background-size: contain;
          width: 20px;
          height: 20px;
          display: block;
        }
        &-des {
          margin: 0;
          color: #ff008e;
          font-size: 20px;
          font-weight: normal;
          line-height: 24px;
        }
      }
    }
    .status {
      height: 46px;
      line-height: 46px;
      background: #f4faff;
      padding: 0 20px 0 40px;
      margin-top: 20px;
      position: relative;
      .text {
        font-size: 20px;
        line-height: 24px;
        color: #00b4ff;
      }
      .count-down {
        margin-left: 10px;
        font-size: 14px;
        line-height: 16px;
        color: #575757;
      }
      .com-btn {
        position: absolute;
        right: 40px;
        bottom: 10px;
        width: 80px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #fff;
        border-radius: 12px;
        background: linear-gradient(270deg, #00b4ff 0%, #1dd0d5 100%);
      }
    }
    .list {
      margin-top: 20px;
      padding: 0 40px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      .left,
      .right {
        .item {
          .label,
          .value {
            font-size: 14px;
            line-height: 24px;
            color: #575757;
          }
          &:not(:first-of-type) {
            margin-top: 10px;
          }
        }
      }
      .btn {
        // width: 140px;
        // height: 32px;
        // line-height: 32px;
        color: #fff;
        text-align: center;
        border-radius: 18px;
        position: absolute;
        background: linear-gradient(270deg, #00b4ff 0%, #1dd0d5 100%);
        bottom: 0;
        right: 40px;
        font-size: 14px;
        padding: 10px 20px;
        .pointer;
      }
    }
    .rate {
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 0 40px;
      font-size: 20px;
      .label {
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        color: #575757;
      }
      /deep/ .star-list span {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: #575757;
      }
    }
  }
</style>
