<template>
  <div class="periods-management">
    <RadioTabs
      :defaultIndex="activeRadioTabsIndex"
      :tabs="radioTabs"
      :tabCustomStyle="tabCustomStyle"
      :active-style="{ color: '#575757' }"
      :inactive-style="{ color: '#fff' }"
      @tabClick="tabClickHandle"
    />
    <!--    <div class="loading" v-loading="loading">-->
    <div class="loading">
      <div class="periods-wrapper">
        <div class="period-item" v-for="(item, index) in periodsList" :key="index">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <div class="banner" v-if="info.status < 8">
        <div class="left">有满意的人才？赶快邀请TA来投标吧</div>
        <div class="right" @click="inviteBtnHandle"
          ><i class="el-icon-s-promotion"></i><span>邀请人才</span></div
        >
      </div>
      <div class="period-state-over-banner" v-if="periodBannerConfig.title">
        <div class="title">{{ periodBannerConfig.title }}</div>
        <div class="sub-title">{{ periodBannerConfig.subTitle }}</div>
        <div class="desc">{{ periodBannerConfig.desc }}</div>
        <div
          class="btn"
          v-if="periodBannerConfig.btnText"
          @click="periodBannerConfig.clickHandle"
          >{{ periodBannerConfig.btnText }}</div
        >
      </div>
      <div class="started" v-if="info.status < 8">
        <StartedPersonList :taskId="info.id" ref="started-person-list" />
      </div>
      <div class="working" v-else>
        <WorkingPersonList
          :info="info"
          :currentPeriod="currentPeriod"
          @updatePrivate2Data="updatePrivate2Data"
        />
      </div>
    </div>
    <inviteDialog
      v-if="isShowInviteModal"
      @close="closeDialogInvite"
      :taskId="info.id"
      :info="info"
    />
    <el-dialog :title="modalConfig.title" :visible.sync="isShowModal" width="480px">
      <div class="modal-two-content">{{ modalConfig.content }}</div>
      <div class="modal-two-btn">
        <div class="cancel" @click="isShowModal = false">取消</div>
        <div class="confirm" @click="modalConfig.confirmHandle">确定</div>
      </div>
    </el-dialog>

    <timeDialog
      :isShow="isShowStartTimePop"
      :period_id="currentPeriod.period_id"
      @close="isShowStartTimePop = false"
    />
  </div>
</template>

<script>
  import RadioTabs from '@/_components/radioTabs/radioTabs';
  import StartedPersonList from '@/pages/home/hirer/task/info/comp/startedPersonList';
  import InviteDialog from '@/pages/home/hirer/task/info/comp/inviteDialog';
  import WorkingPersonList from '@/pages/home/hirer/task/info/comp/workingPersonList';
  import timeDialog from '@/pages/home/hirer/task/info/comp/timeDialog';
  export default {
    components: { RadioTabs, StartedPersonList, InviteDialog, WorkingPersonList, timeDialog },
    name: 'periodsManagement',
    data() {
      return {
        tabCustomStyle: { backgroundColor: '#00b4ff' },
        activeRadioTabsIndex: 0,
        tabMark: -1,
        currentPeriod: {},
        isShowInviteModal: false,
        loading: true,
        currentPeriodId: '1',
        isShowModal: false,
        isShowStartTimePop: false,
        modalConfig: {
          title: '',
          content: '',
          confirmHandle: () => {}
        }
      };
    },
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    watch: {
      info: {
        handler(val) {
          let _id = '';
          const index = val.periods.findIndex((item) => item.is_current);
          if (~index) {
            this.activeRadioTabsIndex = _id = index;
            ~this.tabMark && (_id = this.tabMark);
            this.currentPeriod = val.periods[_id];
          } else {
            this.currentPeriod = val.periods[0];
          }
        },
        immediate: true
      }
    },
    computed: {
      periodBannerConfig() {
        const { status } = this.currentPeriod;
        const is_current = !!this.currentPeriod.is_current;
        const task_status = this.info.status;
        if (task_status === 8) {
          if (is_current && [4, 5, 6].includes(status)) {
            return {
              title: '周期状态',
              subTitle: '若报酬已经全部发放',
              desc: '结束本周期工作，才能开始下一周期',
              btnText: '结束周期',
              clickHandle: () => {
                this.isShowModal = true;
                this.modalConfig = {
                  ...this.modalConfig,
                  title: '本周期工作已结束',
                  content: '是否立即开始下一周期工作？',
                  confirmHandle: () => {
                    this.finishPeriod();
                    this.isShowModal = false;
                  }
                };
              }
            };
          } else if (is_current && status == 1) {
            return {
              title: '周期状态',
              subTitle: '本周期未开始',
              desc: '',
              btnText: '开始工作',
              clickHandle: () => {
                ///api/uc/mini/task/period/start
                // startPeriod
                this.isShowStartTimePop = true;
              }
            };
          } else if (!is_current && status == 1) {
            return {
              title: '周期状态',
              subTitle: '本周期未开始',
              desc: '需结束上一周期工作',
              btnText: ''
            };
          } else {
            return {
              title: '',
              subTitle: '',
              desc: '',
              btnText: ''
            };
          }
        } else {
          return {
            title: '',
            subTitle: '',
            desc: '',
            btnText: ''
          };
        }
      },
      periodsList() {
        console.log(this.currentPeriod, 3333333333333);
        return [
          {
            label: '已托管',
            value: (this.currentPeriod.budget_amount || '--') + '元'
          },
          {
            label: '周期开始',
            value: this.currentPeriod.start_time || '--'
          },
          {
            label: '周期已付',
            value: (this.currentPeriod.grant_amount || '--') + '元'
          },
          {
            label: '分期比例',
            value: this.currentPeriod.budget_percent || '--'
          },
          {
            label: '周期结束',
            value: this.currentPeriod.finish_time || '--'
          }
        ];
      },
      radioTabs() {
        let _arr = [];
        if (Array.isArray(this.info.periods)) {
          const l = this.info.periods.length;
          this.info.periods.forEach((item, index) => {
            _arr.push({
              id: index,
              name: `第${index + 1}/${l}期`
            });
          });
        }
        return _arr;
      }
    },
    methods: {
      tabClickHandle(e) {
        console.log(e);
        // this.loading = true;
        this.tabMark = e.id;
        this.currentPeriod = this.info.periods[e.id];
      },
      closeDialogInvite() {
        this.isShowInviteModal = false;
        this.$refs['started-person-list'].loading = true;
        this.$refs['started-person-list'].getPersonList();
        // getPersonList
      },
      inviteBtnHandle() {
        this.isShowInviteModal = true;
      },
      updatePrivate2Data() {
        this.$emit('updatePrivate2Data');
      },
      finishPeriod() {
        const { period_id } = this.currentPeriod;
        this.$Http
          .post({
            url: '/api/uc/mini/task/period/end',
            params: { period_id }
          })
          .then((res) => {
            res && res.statusCode === 200 &&
              setTimeout(() => {
                location.reload();
              }, 1000);
          });
      }
    }
  };
</script>

<style lang="less" scoped>
  .periods-management {
    background-color: #fff;
    .periods-wrapper {
      padding: 20px 30px;
      display: flex;
      flex-wrap: wrap;
      .period-item {
        width: 33%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: #575757;
        .value {
          margin-left: 20px;
        }
      }
    }
    .banner {
      border-radius: 8px;
      background: #f4faff;
      padding: 10px 30px 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px;
      .left {
        font-size: 15px;
        line-height: 32px;
        color: #575757;
      }
      .right {
        // width: 200px;
        // height: 40px;
        border-radius: 20px;
        background: linear-gradient(270deg, #01c8d4 0%, #03d978 100%);
        text-align: center;
        // line-height: 40px;
        color: #fff;
        font-size:14px;
        padding:5px 15px;
        .pointer;
        .el-icon-s-promotion {
          margin-right: 4px;
        }
      }
    }
    .period-state-over-banner {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 8px;
      background: #f4faff;
      height: 64px;
      margin: 0 30px;
      .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #575757;
        padding: 0 40px;
        border-right: 1px solid #d8d8d8;
      }
      .sub-title {
        margin-left: 40px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #575757;
      }
      .desc {
        margin-left: 20px;
        font-size: 14px;
        line-height: 24px;
      }
      .btn {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 120px;
        height: 32px;
        color: #fff;
        .pointer;
        border-radius: 20px;
        background: #00b4ff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .started {
      padding: 0 30px;
      margin-top: 30px;
    }
  }
  /deep/ .el-dialog__header {
    text-align: center;
    padding-top: 30px !important;
    .el-dialog__title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .modal-two-content {
    text-align: center;
    /deep/ .pass {
      line-height: 30px;
    }

    /deep/ .reward {
      text-align: center;
      .modal-two-btn {
        display: flex;
      }
    }
  }
  .modal-two-btn {
    margin-top: 60px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      .pointer;
      width: 180px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
    }
    div:last-of-type {
     margin-left: 20px;
    }
    .cancel {
      border: 1px solid #dcdddd;
    }
    .confirm {
      background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
      color: #fff;
    }
  }
</style>
