<template>
  <div class="trusteeship-dialog">
    <el-dialog
      :visible="isShow"
      width="480px"
      :close-on-click-modal="false"
      @close="closeDialogHandle"
    >
      <div class="dialog-main">
        <div class="title">
          <h1 class="tit" :style="{ color: hasEnoughMoney ? '' : '#FF008E' }">{{
            hasEnoughMoney ? '托管资金' : '您的余额不足，请先充值'
          }}</h1>
          <p class="sub"
            >仅为暂时冻结账户资金，任务完成后才进行结算，如有结余，将<span
              >返还未支付部分和相应服务费。</span
            ></p
          >
        </div>
        <div class="first" v-if="!showCapitalResult">
          <div class="table">
            <div class="table_0">
              <div class="label">项目预算</div>
              <div class="value">{{ info.salary_amount }} <span>元</span></div>
            </div>
            <div class="table_1">
              <div class="label"
                >服务费（{{ info.commission_rate }}）<i
                  class="el-icon-warning-outline"
                  style="color: #00b4ff"
                ></i
              ></div>
              <div class="value">{{ info.commission_amount }} <span>元</span></div>
            </div>
            <div class="table_2">
              <div class="label">需托管资金</div>
              <div class="value">{{ info.total_need_amount }} <span>元</span></div>
            </div>
            <div class="line"></div>
            <div class="table_3">
              <div class="label">您的帐户余额</div>
              <div class="value">{{ info.available_amount }} <span>元</span></div>
            </div>
            <div class="table_4" v-if="!hasEnoughMoney">
              <div class="label">充值金额</div>
              <div class="value">{{ info.need_recharge_amount }} <span>元</span></div>
            </div>
          </div>
          <div class="btn-group">
            <div class="cancel" @click="closeDialogHandle">取消</div>
            <div class="confirm" @click="dialogConfirmHandle">{{
              hasEnoughMoney ? '托管' : '去充值'
            }}</div>
          </div>
        </div>
        <div class="res" v-else>
          <div class="loading" v-if="capitalLoading"></div>
          <div class="sus" v-else>
            <div class="img">
              <img src="@/assets/icons/_tmp/correct.png" alt="" />
              <span>资金托管成功</span>
            </div>
            <div class="btn" @click="confirmHandle">确定</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'trusteeshipDialog',
    data() {
      return {
        showCapitalResult: false,
        capitalLoading: true
      };
    },
    props: {
      isShow: {
        type: Boolean,
        default: false
      },
      info: {
        type: Object,
        default: () => ({})
      },
      taskId: {
        type: [String, Number],
        default: ''
      }
    },
    computed: {
      hasEnoughMoney() {
        return !this.info.need_recharge_amount;
      }
    },
    methods: {
      dialogConfirmHandle() {
        if (this.hasEnoughMoney) {
          this.$Http
            .post({ url: '/api/uc/task/custody', params: { task_id: this.taskId } })
            .then(({ statusCode }) => {
              if (statusCode === 200) {
                this.$emit('dialogConfirmHandle');
                this.capitalLoading = false;
                this.showCapitalResult = true;
              }
            });
        } else {
          this.$router.push(
            '/home/account/recharge?recharge_money=' + this.info.need_recharge_amount + '&task_id=' + this.taskId
          );
        }
      },
      closeDialogHandle() {
        this.$emit('close');
      },
      confirmHandle() {
        this.$emit('confirm');
        this.$emit('close');
      }
    }
  };
</script>

<style lang="less" scoped>
  .dialog-main {
    min-height: 450px;
    box-sizing: border-box;
    .title {
      padding-bottom: 20px;
      .relative;
      .border-bottom-1px(1px);
      .tit {
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        color: #575757;
        text-align: center;
      }
      .sub {
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;
        span {
          color: #00b4ff;
        }
      }
    }
    .first {
      .table {
        padding-top: 14px;
        div[class^='table'] {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
        }
        .table_2 {
          .label,
          .value {
            font-weight: bold;
          }
          .value {
            color: #00b4ff;
            span {
              color: #575757;
            }
          }
        }
        .table_3 .label,
        .table_3 .value {
          font-weight: bold;
        }
        .table_4 {
          .value {
            color: #ff008e;
            span {
              color: #575757;
            }
          }
        }
        .line {
          height: 1px;
          margin: 10px 0;
          background-color: #e7e7e7;
        }
      }
      .btn-group {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        .cancel,
        .confirm {
          .pointer;
          width: 180px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 20px;
          box-sizing: border-box;
        }
        .cancel {
          border: 1px solid #00b4ff;
          color: #00b4ff;
        }
        .confirm {
          background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
          color: #fff;
        }
      }
    }
    .res {
      padding-top: 60px;
      .loading {
        width: 64px;
        margin: 0 auto;
        height: 64px;
        background: url('~@/assets/icons/_tmp/loading.png');
        background-size: cover;
        animation: rotate 1s infinite;
        @keyframes rotate {
          from {
            transform: rotate(90deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      .sus {
        text-align: center;
        .img {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 64px;
            height: 64px;
          }
          span {
            margin-top: 20px;
          }
        }
        .btn {
          .pointer;
          margin: auto;
          width: 180px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          background: #00b4ff;
          margin-top: 122px;
          color: #fff;
        }
      }
    }
  }
</style>
