<template>
  <div class="work-manager">
    <BaseInfoWrap :info="taskInfo" />
    <div class="state-management">
      <AssetsStateWrap :info="taskInfo" @updatePrivate2Data="updatePrivate2Data" />
      <TaskState :info="taskInfo" />
    </div>
    <div class="periods-management" v-if="(taskInfo.periods || []).length > 0">
      <PeriodsManagement :info="taskInfo" @updatePrivate2Data="updatePrivate2Data" />
    </div>
  </div>
</template>

<script>
  import BaseInfoWrap from '@/pages/home/hirer/task/info/comp/baseinfoWrap';
  import AssetsStateWrap from '@/pages/home/hirer/task/info/comp/assetsStateWrap';
  import TaskState from '@/pages/home/hirer/task/info/comp/taskState';
  import PeriodsManagement from '@/pages/home/hirer/task/info/comp/periodsManagement';
  export default {
    components: { BaseInfoWrap, AssetsStateWrap, TaskState, PeriodsManagement },
    name: 'index.vue',
    data() {
      return {
        sn: '',
        taskInfo: {}
      };
    },
    created() {
      this.sn = this.$route.query.sn;
      this.getTaskPrivate();
    },
    methods: {
      async getTaskPrivate() {
        const { data } = await this.$Http.get({
          url: '/api/task/info/private2',
          params: { sn: this.$route.query.sn }
        });
        this.taskInfo = {
          ...this.taskInfo,
          ...data
        };
      },
      updatePrivate2Data() {
        this.getTaskPrivate();
      }
    }
  };
</script>

<style lang="less" scoped>
  .work-manager {
    background: #f8f8f8;
    .state-management {
      margin-top: 20px;
      display: flex;
      align-items: stretch;
    }
    .periods-management {
      margin-top: 20px;
    }
  }
</style>
