<template>
  <div class="working-person-list">
    <div class="person-manage" v-if="(info.periods || []).length > 0">
      <div class="table-title">
        <div class="title">
          <span class="tit">雇员管理</span>
          <span class="sub">（共{{ periodPersonListConfig.length }}人）</span>
        </div>
        <div class="check-pop">
          <el-dropdown @command="fastOperationHandle">
            <span class="el-dropdown-link">
              一键操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
<!--              <el-dropdown-item command="reminder">催单</el-dropdown-item>-->
              <el-dropdown-item command="pass">验收通过</el-dropdown-item>
              <el-dropdown-item command="reward">发放报酬</el-dropdown-item>
              <!--                  <el-dropdown-item command="evaluate">评价</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table" v-loading="isShowTableLoading">
        <el-table
          ref="multipleTable"
          :data="periodPersonListConfig"
          tooltip-effect="dark"
          stripe
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="name" label="雇员">
            <template v-slot="scope">
              <img class="table-row-name-image" :src="scope.row.freelancer.avatar" />
              <span class="table-row-name-text">{{ scope.row.freelancer.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="plan_salary_amount" label="应付金额(元)" />
          <el-table-column prop="submitResults" label="成果">
            <template v-slot="scope">{{ scope.row.work_result }}</template>
          </el-table-column>
          <el-table-column prop="validatePass" label="验收结果">
            <template v-slot="scope">{{ scope.row.check_result }}</template>
          </el-table-column>
          <el-table-column prop="distributeRewards" label="报酬">
            <template v-slot="scope">{{ scope.row.grant_result }}</template>
          </el-table-column>
          <el-table-column prop="real_salary_amount" label="实付金额(元)" />
          <el-table-column label="操作" width="160">
            <template v-slot="scope">
              <span style="color: #00b4ff" v-if="!scope.row.status">--</span>
              <div class="btn-group" v-else>
                <button v-show="false"
                  class="table-row-reminder-btn"
                  v-if="scope.row.status < 4 && !scope.row.submit_end_time"
                  @click="clickReminderHandle(scope.$index, scope.row)"
                  >催单</button
                >
                <button
                  class="table-row-result-btn"
                  v-if="scope.row.status > 3 || scope.row.submit_check_time"
                  @click="clickResultHandle(scope.$index, scope.row)"
                  >查看成果</button
                >
                <button
                  class="table-row-pass-btn"
                  v-if="[4, 5].includes(scope.row.status)"
                  @click="clickPassHandle(scope.$index, scope.row)"
                  >验收</button
                >
                <button
                  class="table-row-reminded-btn"
                  v-if="scope.row.status < 4 && scope.row.submit_end_time"
                  @click="clickRemindedHandle(scope.$index, scope.row)"
                  >已催单</button
                >
                <!--                    <button-->
                <!--                      class="table-row-evaluate-btn"-->
                <!--                      v-if="scope.row.status === 4"-->
                <!--                      @click="clickEvaluateHandle(scope.$index, scope.row)"-->
                <!--                      >去评价</button-->
                <!--                    >-->
                <button
                  class="table-row-reward-btn"
                  v-if="[6, 7, 8].includes(scope.row.status)"
                  @click="clickRewardHandle(scope.$index, scope.row)"
                  >发放报酬</button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="table-operate">
          <el-checkbox v-model="checked" @change="allCheckHandle">全选</el-checkbox>
          <div class="persons" v-show="checkedPersons > 0">(已选{{ checkedPersons }}人)</div>
          <div class="drop" v-show="checkedPersons > 0">
            <el-dropdown @command="moreOperationHandle">
              <span class="el-dropdown-link">
                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="reminder">催单</el-dropdown-item>
                <el-dropdown-item command="pass">验收通过</el-dropdown-item>
                <el-dropdown-item command="reward">发放报酬</el-dropdown-item>
                <!--                    <el-dropdown-item command="evaluate">评价</el-dropdown-item>-->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :hide-on-single-page="true"
            :page-size="10"
            :total="pageTotal"
            @current-change="hirePersonListPaginationHandle"
          />
        </div>
      </div>
    </div>
    <el-dialog title="催缴工作成果" :visible.sync="reminderDialogVisible" width="480px">
      <div class="reminder-dialog-content"
        ><section
          >平台将会通知雇员，雇员需在7天内提交工作成果。如逾期未提交，平台将自动<span>中止工作并发起仲裁</span>，请谨慎使用！您可先与雇员联系沟通。</section
        ></div
      >
      <div class="reminder-dialog-btn">
        <div class="cancel" @click="reminderDialogConfirm">催促</div>
        <div class="confirm" @click="reminderDialogVisible = false">取消</div>
      </div>
    </el-dialog>
    <el-dialog :title="fastOptPop.title" :visible.sync="isShowFastOptPop" width="480px">
      <div class="modal-fast">{{ fastOptPop.content }}</div>
      <div class="modal-fast-btn">
        <div class="cancel" @click="isShowFastOptPop = false">取消</div>
        <div class="confirm" @click="fastOptPop.confirmHandle">确定</div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="isShowQuickOptPop" width="480px">
      <div class="quick-opt-pop-content">{{ quickOptPopContent }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="nextOptHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'workingPersonList',
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      currentPeriod: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        reminderDialogVisible: false,
        periodPersonList: {},
        checked: false,
        checkedPersons: '0',
        isShowTableLoading: false,
        tmpReminderParams: {},
        multipleSelection: [],
        fastOptPop: {},
        isShowFastOptPop: false,
        isShowQuickOptPop: false,
        quickOptPopContent: ''
      };
    },
    computed: {
      periodPersonListConfig() {
        return this.periodPersonList.list || [];
      },
      pageTotal() {
        return this.periodPersonList?.page?.total || 1;
      }
    },
    watch: {
      currentPeriod: {
        handler() {
          this.isShowTableLoading = true;
          this.initPersonList();
        },
        deep: true,
        immediate: true
      }
    },
    async created() {},
    methods: {
      refreshData() {
        this.isShowTableLoading = true;
        this.checked = false;
        this.initPersonList();
        this.$emit('updatePrivate2Data');
      },
      async initPersonList() {
        const { data: periodPersonList } = await this.$Http.get({
          url: '/api/uc/mini/task/hirer/periodworkitems',
          params: { period_id: this.currentPeriod.period_id }
        });
        this.isShowTableLoading = false;
        this.periodPersonList = periodPersonList;
      },
      async hirePersonListPaginationHandle(e) {
        this.isShowTableLoading = true;
        const page = e;
        const { data: periodPersonList } = await this.$Http.get({
          url: '/api/uc/mini/task/hirer/periodworkitems',
          params: { period_id: this.currentPeriod.period_id, page }
        });
        this.periodPersonList = periodPersonList;
        this.isShowTableLoading = false;
      },
      fastOperationHandle(command, needPop = true) {
        const { period_id } = this.currentPeriod;
        const _handle = {
          reminder: () => {
            const type = 'all';
            this.reminderHandle({ period_id, type });
            // this.refreshData();
          },
          pass: () => {
            if (needPop) {
              this.fastOptPop = {
                title: '一键验收说明',
                content:
                  '该操作将所有“已提交成果”且“等待验收”的工作成果全部验收通过。（已验收过的会自动忽略），您是否确定一键验收通过？',
                confirmHandle: () => {
                  this.fastOperationHandle('pass', false);
                }
              };
              this.isShowFastOptPop = true;
              return;
            }
            this.isShowFastOptPop = false;
            this.$Http
              .post({ url: '/api/uc/task/work/checkall', params: { period_id } })
              .then(({ data }) => {
                const { success_count } = data;
                const { fail_count } = data;
                this.quickOptPopContent = `成功${success_count}个,失败${fail_count}个`;
                this.isShowQuickOptPop = true;
              });
          },
          reward: () => {
            if (needPop) {
              this.fastOptPop = {
                title: '一键发放报酬说明',
                content:
                  ' 该操作会为所有“验收通过”并且“未发薪资”的自由职业者批量发放薪资。（已发放的自由职业者自动忽略），您是否确定一键发薪？',
                confirmHandle: () => {
                  this.fastOperationHandle('reward', false);
                }
              };
              this.isShowFastOptPop = true;
              return;
            }
            this.isShowFastOptPop = false;
            this.$Http
              .post({ url: '/api/uc/task/work/grantall', params: { period_id } })
              .then(({ data }) => {
                const { success_count } = data;
                const { fail_count } = data;
                this.quickOptPopContent = `成功${success_count}个,失败${fail_count}个`;
                this.isShowQuickOptPop = true;
              });
          },
          evaluate: () => {}
        };
        _handle[command]();
      },
      handleSelectionChange(val) {
        console.log(val);
        this.multipleSelection = val;
        const l = this.multipleSelection.length;
        this.checkedPersons = l;
      },
      allCheckHandle(e) {
        if (e) {
          const l = this.periodPersonListConfig.length;
          this.checkedPersons = l;
          this.$refs.multipleTable.clearSelection();
          this.periodPersonListConfig.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.checkedPersons = '0';
          this.$refs.multipleTable.clearSelection();
        }
      },
      moreOperationHandle(command) {
        console.log(command);
        if (this.multipleSelection.length == 0) return;
        const { period_id } = this.currentPeriod;
        const work_item_ids = this.multipleSelection.map((item) => item.id);
        const type = 'batch';
        const _handle = {
          reminder: () => {
            this.reminderHandle({ period_id, type, work_item_ids });
            // this.refreshData();
          },
          pass: () => {
            this.$Http
              .post({
                url: '/api/uc/task/work/checkbatch',
                params: { period_id, work_item_ids }
              })
              .then(({ data }) => {
                const total = work_item_ids.length;
                const { success_count } = data;
                const { fail_count } = data;
                this.quickOptPopContent = `验收${total}个，成功${success_count}个,失败${fail_count}个`;
                this.isShowQuickOptPop = true;
              });
          },
          reward: () => {
            this.$Http
              .post({ url: '/api/uc/task/work/batchgrant', params: { work_item_ids } })
              .then(({ data }) => {
                const total = work_item_ids.length;
                const { success_count } = data;
                const { fail_count } = data;
                this.quickOptPopContent = `发放${total}个，成功${success_count}个,失败${fail_count}个`;
                this.isShowQuickOptPop = true;
              });
          },
          evaluate: () => {}
        };
        console.log(_handle[command]);
        _handle[command]();
      },

      clickResultHandle(index, row) {
        this.$router.push('/home/hirer/task/pass?id=' + row.id);
      },
      clickPassHandle(index, row) {
        this.$router.push('/home/hirer/task/pass?id=' + row.id);
      },

      clickEvaluateHandle() {},
      clickRewardHandle(index, row) {
        this.fastOptPop = {
          title: '',
          content: '确认为该雇员发放报酬？',
          confirmHandle: () => {
            this.$Http
              .post({ url: '/api/uc/task/work/grant', params: { work_item_id: row.id } })
              .then(({ statusCode }) => {
                if (statusCode == 200) {
                  this.isShowFastOptPop = false;
                  this.$notify({
                    message: '发放成功',
                    type: 'success'
                  });
                  this.refreshData();
                }
              });
          }
        };
        this.isShowFastOptPop = true;
      },
      clickCheckHandle(index, row) {
        console.log(index, row);
      },
      //------------------reminder
      clickReminderHandle(index, row) {
        console.log(index, row);
        if (row.submit_end_time) return;
        const { period_id } = this.currentPeriod;
        const type = 'single';
        const freelancer_id = row.freelancer.id;
        this.reminderHandle({ period_id, type, freelancer_id });
      },
      clickRemindedHandle(index, row) {
        console.log(index, row);
      },
      async reminderHandle(params, isShowDialog = true) {
        if (isShowDialog) {
          this.reminderDialogVisible = true;
          this.tmpReminderParams = params;
          return;
        }
        const { statusCode } = await this.$Http.post({
          url: '/api/uc/mini/task/hirer/reminder',
          params
        });
        this.reminderDialogVisible = false;
        if (statusCode == 200) {
          this.$notify({
            message: '催单成功',
            type: 'success'
          });
          this.refreshData();
        }
      },
      reminderDialogConfirm() {
        this.reminderHandle(this.tmpReminderParams, false);
      },
      nextOptHandle() {
        this.isShowQuickOptPop = false;
        this.refreshData();
      }
    }
  };
</script>

<style lang="less" scoped>
  .working-person-list {
    padding: 10px 30px 30px;
    .person-manage {
      .table-title {
        display: flex;
        justify-content: space-between;
        .title {
          .tit {
            font-size: 16px;
            line-height: 16px;
            color: #000000;
          }
          .sub {
            margin-left: 0px;
            font-size: 14px;
            line-height: 14px;
            color: #575757;
          }
        }
        .check-pop {
          .pointer;
          width: 120px;
          height: 24px;
          line-height: 24px;
          border-radius: 4px;
          background: #f4f4f4;
          text-align: center;
        }
      }
      .table {
        margin-top: 10px;
        /deep/.el-table__cell {
          padding: 12px 0 !important;
        }
      }
      .bottom {
        margin-top: 20px;
        .flex;
        justify-content: space-between;
        .table-operate {
          .flex;
          align-items: center;
          /deep/.el-checkbox {
            padding-left: 21px;
            .el-checkbox__label {
              font-size: 14px;
            }
          }
          .persons {
            margin-left: 20px;
            font-size: 12px;
            color: #575757;
          }
          .drop {
            margin-left: 20px;
            .pointer;
            width: 120px;
            height: 24px;
            line-height: 24px;
            border-radius: 4px;
            font-size: 14px;
            background: #f4f4f4;
            text-align: center;
          }
        }
      }
      .table-row-name-image {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        vertical-align: middle;
      }
      .table-row-name-text {
        margin-left: 8px;
        vertical-align: middle;
      }
      .btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        button[class^='table-row'] {
          background-color: transparent;
          color: #00b4ff;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          padding: 0 2px;
        }
        & > button[class='table-row-reminded-btn'] {
          color: #ff008e;
        }
      }
    }
  }
  //------------------table start
  /deep/th[colspan='1'] {
    background-color: #f4faff;
    position: relative;
    &:not(:first-of-type):not(:nth-last-of-type(2)) {
      .border-right-1px(1px,14px,14px);
    }
    .el-checkbox {
      visibility: hidden;
    }
  }
  /deep/ .el-checkbox__inner {
    border-radius: 50%;
  }
  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #f4faff;
  }
  //------------------table end
  /deep/ .el-dialog__header {
    text-align: center;
    font-weight: bold;
  }
  .reminder-dialog-content,
  .modal-fast {
    font-size: 16px;
    line-height: 30px;
    span {
      color: #ff008e;
    }
  }
  .reminder-dialog-btn,
  .modal-fast-btn {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    .cancel,
    .confirm {
      width: 180px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      box-sizing: border-box;
      .pointer;
    }
    .cancel {
      margin-right: 20px;
      border: 1px solid #dcdddd;
    }
    .confirm {
      border: 1px solid #00b4ff;
      color: #00b4ff;
    }
  }
</style>
