<template>
  <div class="assets-state-wrap">
    <div class="title">
      <i class="blue-block"></i>
      <span>资金状态</span>
      <el-popover placement="top-start" width="360" trigger="hover">
        <p v-for="con in content" :key="con">{{ con }}</p>
        <i slot="reference" class="el-icon-warning-outline"></i>
      </el-popover>
    </div>
    <div class="list">
      <div class="list-left">
        <div class="item" v-for="(item, index) in listConfig.left" :key="index">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <div class="list-right" v-if="hadTrusteeship">
        <div class="item" v-for="(item, index) in listConfig.right" :key="index">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <div class="btn" v-else @click="trusteeshipHandle">托管资金</div>
      <!--      <div class="task-balance" v-if="info.balance_amount != 0">-->
      <!--        <div class="label">结余金额：</div>-->
      <!--        <div class="value">{{ info.balance_amount }}元</div>-->
      <!--      </div>-->
    </div>
    <TrusteeshipDialog
      :isShow="isShowTrusteeshipPop"
      :info="capitalData"
      :taskId="info.id"
      @close="isShowTrusteeshipPop = false"
      @confirm="dialogConfirmHandle"
    />
  </div>
</template>

<script>
  import TrusteeshipDialog from '@/pages/home/hirer/task/info/comp/trusteeshipDialog';
  export default {
    components: { TrusteeshipDialog },
    name: 'assetsStateWrap',
    data() {
      return {
        content: [
          '1、招标结束前托管资金按最大金额计算，如若有结余将在招标结束后返还部分资金及相对应的服务费；',
          '2、招标结束后可按雇佣人数实际金额托管；',
          '3、任务结束后如有结余也将返还部分资金及相对应的服务费。'
        ],
        taskBalance: {
          label: '任务结余：',
          value: '5.5元'
        },
        hadTrusteeship: true,
        hadOver: false,
        capitalData: {},
        isShowTrusteeshipPop: false
      };
    },
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    watch: {
      info(val) {
        !val.is_capital_custody && this.getCapitalData();
        this.hadTrusteeship = !!val.is_capital_custody;
      }
    },
    created() {},
    computed: {
      listConfig() {
        const backPrice = {
          label: '已返还金额：',
          value: `${this.info.back_amount || 0}元`
        };
        let _list = {
          left: [
            {
              label: '需托管资金：',
              value: `${this.info.custody_amount || 0}元`
            },
            {
              label: '总薪酬金额：',
              value: `${this.info.salary_amount || 0}元`
            },
            {
              label: '服务费费率：',
              value: `${this.info.commission_rate || 0}%`
            },
            {
              label: '服务费金额：',
              value: `${this.info.commission_amount || 0}元`
            }
          ],
          right: [
            {
              label: '已产生费用：',
              value: this.info.generated_amount == 0 ? '--' : this.info.generated_amount + '元'
            },
            {
              label: '已发放薪酬：',
              value: this.info.generated_amount == 0 ? '--' : this.info.grant_amount + '元'
            },
            {
              label: '已收取服务费：',
              value:
                this.info.generated_amount == 0 ? '--' : this.info.grant_commission_amount + '元'
            }
          ]
        };
        if (this.info.back_amount == 0) {
          return _list;
        } else {
          _list.left.push(backPrice);
          return _list;
        }
      }
    },
    methods: {
      async getCapitalData() {
        const { data: capitalData } = await this.$Http.get({
          url: '/api/uc/task/custody/perquery',
          params: { task_id: this.info.id }
        });
        this.capitalData = capitalData;
      },
      trusteeshipHandle() {
        this.getCapitalData();
        this.isShowTrusteeshipPop = true;
      },
      dialogConfirmHandle() {
        this.$emit('updatePrivate2Data');
      }
    }
  };
</script>

<style lang="less" scoped>
  .assets-state-wrap {
    background-color: #fff;
    padding: 30px;
    flex: 1;
    position: relative;
    .title {
      display: flex;
      align-items: center;
      .blue-block {
        display: inline-block;
        width: 8px;
        height: 24px;
        border-radius: 1px;
        background: #00b4ff;
      }
      span {
        font-size: 18px;
        line-height: 24px;
        color: #575757;
        margin: 0 16px 0 10px;
      }
      .el-icon-warning-outline {
        color: #00b4ff;
        font-size: 14px;
        .pointer;
      }
    }
    .list {
      margin-top: 18px;
      display: flex;
      align-items: flex-start;
      //position: relative;
      .item {
        display: flex;
        align-items: center;
        &:not(:first-of-type) {
          margin-top: 10px;
        }
        &:first-of-type {
          .label {
            font-size: 14px;
          }
          .value {
            font-size: 14px;
            color: #00b4ff;
          }
        }
        .label {
          min-width: 108px;
          text-align: right;
          font-size: 14px;
          line-height: 24px;
          color: #575757;
        }
        .value {
          min-width: 120px;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
      .list-right {
        margin-left: 20px;
      }
      .btn {
        position: absolute;
        right: 40px;
        bottom: 40px;
        // height: 40px;
        padding:0 20px;
        // line-height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 20px;
        background: linear-gradient(270deg, #00b4ff 0%, #1dd0d5 100%);
        font-size:14px;
        padding:10px 20px;
        .pointer;
      }
      .task-balance {
        position: absolute;
        left: calc(228px + 20px);
        bottom: 0;
        font-size: 18px;
        display: flex;
        .label {
          min-width: 108px;
          text-align: right;
        }
        .value {
          min-width: 120px;
          color: #00b4ff;
          text-align: left;
        }
      }
    }
  }
</style>
