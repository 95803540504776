<template>
  <div class="started-person-list">
    <div class="choose-tags">
      <span
        class="check"
        :class="{ 'check-active': activeIndex === index }"
        v-for="(item, index) in buttons"
        :key="index"
        @click="checkoutTab(item, index)"
        >{{ item.name }}</span
      >
    </div>
    <div class="person-list" v-if="personList.length > 0">
      <div class="modal" v-loading="loading">
        <div class="person" v-for="(item, index) in personList" :key="index">
          <div class="top">
            <div class="base">
              <div class="avatar"><img :src="get(item.freelancer, 'avatar', '')" alt="head" /></div>
              <div class="name">{{ item.freelancer.name }}</div>
              <div class="icons">
                <PersonIcon
                  v-for="(item, idx) in certificationsIcons(item)"
                  :key="idx"
                  :name="item"
                />
              </div>
            </div>
            <div class="price">
              <span>邀标价</span>
              <p
                ><span class="num">{{ item.bid_price }}</span
                ><span class="unit">{{ item.pay_way == 1 ? '元' : '元/小时' }}</span></p
              >
            </div>
            <div class="status">{{ personStateTrans(item.status, item.bid_type) }}</div>
            <div class="buttons" v-if="personBtnTrans(item.status, item.bid_type)"
              ><div class="btn" @click="personOptHandle(item)">{{
                personBtnTrans(item.status, item.bid_type)
              }}</div></div
            >
          </div>
          <div class="bottom" v-if="item.id && item.freelancer.id !== 0">
            <div class="person-intro-info">
              <div class="info-status">
                <div class="info-status-item" v-if="item.freelancer.settled_days">
                  <div class="label">入驻时间：</div>
                  <div class="value">{{ item.freelancer.settled_days }}</div>
                </div>
                <div class="info-status-item" v-if="item.freelancer.finish_project_num">
                  <div class="label">已完成的工作：</div>
                  <div class="value">{{ item.freelancer.finish_project_num }}个项目</div>
                </div>
                <div class="info-status-grade" v-if="item.freelancer.review_count > 0">
                  <div class="grade-star">
                    <StarList :total="item.freelancer.review_score" />
                  </div>
                  <div class="grade-com">{{
                    evaluationTransform(item.freelancer.review_count)
                  }}</div>
                </div>
              </div>
              <div class="info-skills" v-if="item.freelancer.skills.length > 0">
                <div>职业技能：</div>
                <div class="skills-list">
                  <span v-for="(item, idx) in item.freelancer.skills" :key="idx">{{
                    item.name
                  }}</span>
                </div>
              </div>
              <div class="intro-write" v-if="item.apply_memo">
                <div class="write-time">
                  <i class="icon-pencil"></i>
                  <span>写给雇主</span>
                  <span style="font-size: 12px">({{ item.apply_time }})</span>
                </div>
                <div class="write-content">
                  <TextExpand :pid="index">{{ item.apply_memo }}</TextExpand>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-data" v-else>
      <img src="https://static.gogowork.cn/src/static/nopermission.png" alt="" />
      <p>{{ noDataDescription }}</p>
    </div>
    <div class="pagination">
      <el-pagination
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :hide-on-single-page="true"
        :page-size="20"
        :total="pageTotal"
        @current-change="startedPersonListPaginationHandle"
      />
    </div>
    <el-dialog :title="modalConfig.title" :visible.sync="isShowCancelPop" width="30%">
      <span>{{ modalConfig.content }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowCancelPop = false">取 消</el-button>
        <el-button type="primary" @click="modalConfig.confirmHandle()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import PersonIcon from './PersonIcon';
  import { StarList } from '@/_components/StarList';
  import { TextExpand } from '@/_components/TextExpand';
  import { get } from 'lodash-es';
  export default {
    components: { PersonIcon, StarList, TextExpand },
    name: 'startedPersonList',
    data() {
      return {
        get,
        noDataDescription: '暂无数据',
        activeIndex: 0,
        loading: true,
        buttons: [
          {
            id: 'all',
            name: '全部'
          },
          {
            id: 'b',
            name: '投标者'
          },
          {
            id: 'i',
            name: '已邀请'
          },
          {
            id: 'h',
            name: '已雇佣'
          }
        ],
        personList: [],
        isShowCancelPop: false,
        tmp_bid_id: '',
        activeId: 'all',
        modalConfig: {
          title: '',
          content: '',
          confirmHandle: () => {}
        },
        personListWrap: {}
      };
    },
    props: {
      taskId: {
        type: [String, Number],
        default: ''
      }
    },
    computed: {
      pageTotal() {
        return this.personListWrap?.page?.total || 1;
      }
    },
    created() {
      this.getPersonList();
    },
    methods: {
      certificationsIcons(item) {
        const certifications = item.freelancer.certifications;
        let _arr = [];
        item.freelancer.is_recommend === 1 && _arr.push('platform-certification');
        certifications.mobile === 9 && _arr.push('mobile-pay');
        certifications.email === 9 && _arr.push('email-certification');
        certifications.real_name === 9 && _arr.push('authentication');
        certifications.company === 9 && _arr.push('enterprise-certification');
        return _arr;
      },
      checkoutTab(e, index) {
        console.log(e);
        this.activeIndex = index;
        this.activeId = e.id;
        this.loading = true;
        this.getPersonList(e.id);
        const _obj = {
          a: '暂无数据',
          b: '暂无人投标，请耐心等待~',
          i: '您还未邀请人才',
          h: '您还未雇佣人才'
        };
        this.noDataDescription = _obj[e.id];
      },
      async getPersonList(type = this.activeId, page = 1) {
        const { data } = await this.$Http.get({
          url: '/api/uc/mini/task/bids',
          params: {
            task_id: this.taskId,
            status_group: type,
            page: page
          }
        });
        this.personListWrap = data;
        this.loading = false;
        this.personList = [...data.list];
      },
      startedPersonListPaginationHandle(e) {
        const page = e;
        this.getPersonList(this.activeId, page);
      },
      evaluationTransform(val = 0) {
        if (val > 0) {
          return `（${val}条评论）`;
        } else {
          return '（暂无评论）';
        }
      },
      personBtnTrans(status, bid_type) {
        //1  '拒绝邀请', 2 '取消投标', 3  '投标', 9 '已雇佣'
        //bid_type:1 自己投标 2 邀请
        return this.stateTransformHandle({ status, bid_type });
      },
      personStateTrans(status, bid_type) {
        return this.stateTransformHandle({ status, bid_type }, 'state');
      },
      personOptHandle(item) {
        this.tmp_bid_id = item.id;
        this.stateTransformHandle(item, 'event')();
      },
      stateTransformHandle({ status, bid_type, id }, type = 'button') {
        const button = { 91: '取消雇佣', 32: '取消邀请', 31: '雇佣TA' };
        const state = { 91: '已雇佣', 92: '已接受邀请', 32: '已邀请', 31: '投标' };
        console.log(type);
        const event = {
          91: () => {
            //
            this.modalConfig = {
              ...this.modalConfig,
              title: '取消雇佣',
              content: '确定取消雇佣此投标者？取消后不能再次雇佣。',
              confirmHandle: () => {
                this.cancelHirePopConfirm();
              }
            };
            this.isShowCancelPop = true;
          },
          32: () => {
            //
            this.modalConfig = {
              ...this.modalConfig,
              title: '取消邀请',
              content: '您是否确定取消邀请？',
              confirmHandle: () => {
                this.cancelInvitePopConfirm();
              }
            };
            this.isShowCancelPop = true;
          },
          31: () => {
            //
            this.loading = true;
            this.$Http
              .post({
                url: '/api/uc/mini/task/bid/employ',
                params: {
                  bid_id: id
                }
              })
              .then(() => {
                this.getPersonList(this.activeId);
              });
          }
        };
        if (type === 'button') {
          return button[status * 10 + bid_type];
        }
        if (type === 'state') {
          return state[status * 10 + bid_type];
        }
        if (type === 'event') {
          return event[status * 10 + bid_type];
        }
      },
      async cancelHirePopConfirm() {
        this.isShowCancelPop = false;
        this.loading = true;
        const { statusCode } = await this.$Http.post({
          url: '/api/uc/mini/task/bid/cancel',
          params: { bid_id: this.tmp_bid_id }
        });
        this.loading = false;
        if (statusCode == 200) {
          await this.getPersonList(this.activeId);
        }
      },
      async cancelInvitePopConfirm() {
        this.isShowCancelPop = false;
        this.loading = true;
        const { statusCode } = await this.$Http.post({
          url: '/api/task/bid/invite/cancel',
          params: { task_id: this.taskId, bid_id: this.tmp_bid_id }
        });
        this.loading = false;
        if (statusCode == 200) {
          await this.getPersonList(this.activeId);
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .started-person-list {
    padding-bottom: 40px;
    .choose-tags {
      display: flex;
      align-items: center;
      .tit {
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        color: #575757;
      }
      .check {
        line-height: 14px;
        text-align: center;
        .pointer;
        border-radius: 4px;
        box-sizing: border-box;
        color: #575757;
        border: 1px solid #b5b5b5;
        margin-right: 20px;
        font-size: 14px;
        padding: 5px 15px;
      }
      .check-active {
        color: #00b4ff;
        border: 1px solid #00b4ff;
      }
    }
    .person-list {
      margin-top: 10px;
      .person {
        margin-top: 10px;
        .top {
          border-radius: 4px;
          background: #f4faff;
          padding: 10px 20px;
          height: 60px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          & > div {
            height: 100%;
            position: relative;
            .border-right-1px-color(1px,#dcdddd,2px,2px);
          }
          .base {
            width: 38%;
            display: flex;
            align-items: center;
            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
            }
            .name {
              margin-left: 20px;
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              color: #575757;
            }
            .icons {
              display: flex;
              margin-left: 5px;
              align-items: center;
              /deep/.person-icon + .person-icon {
                margin-left: 10px;
              }
            }
          }
          .price {
            width: 34%;
            display: flex;
            align-items: center;
            padding-left: 34px;
            position: relative;
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            color: #575757;
            p {
              margin-left: 20px;
              .num {
                font-weight: bold;
              }
              .unit {
                margin-left: 4px;
              }
            }
          }
          .status {
            width: 14%;
            flex: 1;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #575757;
          }
          .buttons {
            width: 14%;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            .btn {
              color: #fff;
              background: #00b4ff;
              width: 80px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              .pointer;
              border-radius: 18px;
              font-size: 12px;
            }
          }
        }
        .bottom {
          overflow: hidden;
          .person-intro-info {
            margin-top: 20px;
            color: #575757;
            .info-status {
              .flex;

              &-item {
                .flex;
                align-items: center;
                font-size: 14px;
                line-height: 20px;
                .value {
                  font-weight: bold;
                }
                & + .info-status-item {
                  margin-left: 20px;
                }
              }

              &-grade {
                .flex;
                margin-left: 20px;
                .grade-com {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }

            .info-skills {
              margin-top: 10px;
              color: #575757;
              font-size: 14px;
              .flex;

              .skills-list {
                span {
                  position: relative;
                  font-size: 14px;
                  .pointer;

                  &:not(:last-of-type)::after {
                    content: '/';
                    position: absolute;
                    right: -10px;
                  }
                }

                span + span {
                  margin-left: 20px;
                }
              }
            }
            .intro-write {
              margin-top: 20px;
              color: #575757;
              font-size: 14px;
              .write-time {
                & > i {
                  width: 20px;
                  height: 13px;
                }
                & > span {
                  margin-left: 10px;

                  &:first-of-type {
                    margin-left: 5px;
                  }
                }
              }

              .write-content {
                margin-top: 10px;
                & > p {
                  padding-right: 10px;
                  .one-text-ellipsis;
                }
              }
            }
          }
        }
      }
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 0 100px;
      img {
        width: 240px;
        height: 240px;
      }
      p {
        margin-top: 20px;
        font-size: 16px;
        line-height: 32px;
      }
    }
    .pagination {
      text-align: right;
      margin-top: 20px;
    }
  }
</style>
