<template>
  <div class="base-info-wrap">
    <div class="title">
      <div class="left">
        <span>任务编号：</span>
        <span>{{ info.sn }}</span>
      </div>
      <div class="right" @click="jumpBack">返回列表</div>
    </div>
    <div class="task-title">
      <div class="left">{{ info.subject }}</div>
      <div class="right">
        <div class="price">{{ pricePerPerson }}</div>
        <div class="des">{{info.show_type==1? '公开任务': '定向任务'}} </div>
      </div>
    </div>
    <div class="base">
      <div class="icon-item" v-for="(icon, index) in iconsList" :key="index">
        <Isvg :icon-class="icon.icon" class-name="icon-message" />
        <span class="icon-label">{{ icon.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      pricePerPerson() {
        if (this.info.max_price === this.info.min_price) {
          return `￥${this.info.max_price}元/人`;
        } else {
          return `￥${this.info.min_price}-${this.info.max_price}元/人`;
        }
      },
      iconsList() {
        const { work_location = 1 } = this.info;
        const { pay_cycle = 1 } = this.info;
        let payText = '';
        // 付款类型 1按周付 2按月付 3全额一次性 4分期支付
        if (pay_cycle == 1) {
          payText = '按周支付';
        } else if (pay_cycle == 2) {
          payText = '按月支付';
        } else if (pay_cycle == 3) {
          payText = '全额支付';
        } else {
          payText = `分${this.info.plan_period_count}期支付`;
        }

        return [
          {
            id: 'hire',
            label: work_location == 1 ? '线上办公' : '线下办公',
            icon: work_location == 1 ? 'office-online' : 'office-offline'
          },
          {
            id: 'hire',
            label: payText,
            icon: 'week-pay'
          },
          {
            id: 'endTime',
            label: `招标截止 ${this.info.bid_select_end_time}`,
            icon: 'sandy-clock'
          }
        ];
      }
    },
    methods: {
      jumpBack() {
        this.$router.go(-1);
      }
    }
  };
</script>

<style lang="less" scoped>
  .base-info-wrap {
    background: #fff;
    padding: 40px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 12px;
        line-height: 12px;
        color: #9c9c9c;
      }
      .right {
        font-size: 14px;
        line-height: 14px;
        color: #00b4ff;
        .pointer;
      }
    }
    .task-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      .left {
        font-size: 18px;
        line-height: 32px;
        color: #000000;
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 280px;
        height: 32px;
        border-radius: 4px;
        overflow: hidden;
        .price {
          width: 160px;
          height: 32px;
          line-height: 32px;
          text-align: right;
          padding: 0 16px;
          border: 1px solid #1dd0d5;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
        }
        .des {
          height: 32px;
          padding: 4px 8px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          text-align: center;
          border-radius: 0px 4px 4px 0px;
          background: #00b4ff;
          color: #fff;
        }
      }
    }
    .base {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .icon-item {
        margin-right: 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        .icon-label {
          font-size: 14px;
          color: #575757;
          margin-left: 8px;
        }
      }
    }
  }
</style>
