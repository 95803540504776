<template>
  <div class="wrapper">
    <el-dialog :visible.sync="dialogVisible" width="480px" @close="$emit('close')">
      <div class="title">邀请人才</div>
      <div class="tabs">
        <div
          class="invite-mobile"
          :class="{ 'active-tab': activeIndex == 0 }"
          @click="changeTabHandle(0)"
        >
          <span class="tit">手机号码邀请</span>
          <span class="special">特定邀请</span>
        </div>
        <div
          class="excel-invite"
          :class="{ 'active-tab': activeIndex == 1 }"
          @click="changeTabHandle(1)"
        >
          <span class="tit">导入excel表格</span>
          <span class="special">批量邀请</span>
        </div>
      </div>
      <div class="mobile-content" v-if="activeIndex == 0">
        <div class="loading" v-if="isShowInputLoading"></div>
        <div class="success" v-if="isShowInputSuccess"
          ><div class="sus">
            <div class="img">
              <img src="@/assets/icons/_tmp/correct.png" alt="" />
              <span>邀请成功，共邀请{{ inviteList.length }}人</span>
            </div>
            <div class="btn" @click="$emit('close')">确定</div>
          </div></div
        >
        <div class="error" v-if="isShowInputError">
          <div class="title">
            <i class="el-icon-warning-outline"></i>
            <p>错误提示</p>
          </div>
          <div class="error-main">
            <div class="main-wrapper" v-for="(item, index) in errorData" :key="index">
              <p>第{{ item.row }}行,{{ item.msg }}</p>
            </div>
          </div>
          <div class="error-btn" @click="rewriteHandle">重新填写</div>
        </div>
        <div v-if="isShowInput">
          <div class="t-head">
            <div class="id"></div>
            <div class="mobile">手机</div>
            <div class="name">姓名</div>
            <div class="bid-price">邀标价(元)</div>
            <div class="operation"></div>
          </div>
          <div class="t-body" ref="row">
            <div class="row" v-for="(item, index) in inviteList" :key="index">
              <div class="wrap">
                <div class="id">{{ index + 1 }} </div>
                <div class="mobile" :class="{ 'has-value': item.mobile && item.mobileBlur }">
                  <input
                    type="text"
                    v-model="item.mobile"
                    placeholder="手机号码"
                    @blur="item.mobileBlur = true"
                    @focus="item.mobileBlur = false"
                /></div>
                <div class="name" :class="{ 'has-value': item.name && item.nameBlur }">
                  <input
                    type="text"
                    v-model="item.name"
                    placeholder="姓名"
                    @blur="item.nameBlur = true"
                    @focus="item.nameBlur = false"
                /></div>
                <div class="bid-price" :class="{ 'has-value': item.price && item.priceBlur }">
                  <input
                    type="number"
                    v-model="item.price"
                    placeholder="请输入"
                    :disabled="isFixed"
                    @blur="item.priceBlur = true"
                    @focus="item.priceBlur = false"
                    @input="priceInputHandle(item.price, index)"
                /></div>
                <div class="operation">
                  <i
                    class="el-icon-remove-outline"
                    v-if="inviteList.length > 1"
                    @click="deleteInviteListItemHandle(index)"
                  ></i>
                  <i
                    class="el-icon-circle-plus-outline"
                    @click="addInviteListItemHandle"
                    v-if="index === inviteList.length - 1"
                  ></i>
                </div>
              </div>
              <div class="error-info">{{ item.errorInfo }}</div>
            </div>
          </div>
          <div class="btn-group">
            <div class="cancel" @click="cancelHandle">取消</div>
            <div class="confirm" @click="confirmHandle" :class="{ disabled: disabled }">确定</div>
          </div>
        </div>
      </div>
      <div class="mobile-content excel" v-if="activeIndex == 1">
        <div class="contain" v-if="isShowExcel">
          <div class="template">
            <div class="t-left">
              <p>邀请投标人数过多时，</p>
              <p>可使用excel表格批量导入</p>
            </div>
            <div class="t-right">
              <div
                class="downLoad"
                @click="
                  jumpUrl(
                    'http://static_test.gogowork.cn/%E5%85%AB%E7%88%AA%E7%81%B5%E5%B7%A5%E9%82%80%E8%AF%B7%E6%A8%A1%E6%9D%BF.xlsx'
                  )
                "
              >
                <i></i>
                <span>下载模板</span>
              </div>
            </div>
          </div>
          <div class="drag" v-if="isShowExcel">
            <el-upload
              ref="uploadAttr"
              class="upload-demo"
              drag
              accept="text/csv,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,image/gif,image/jpeg,image/png,application/zip,application/rar"
              :action="'/api/task/bid/invite/import'"
              :data="{ file_type: 'doc', task_id: taskId }"
              :headers="headers"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="uploadAttrSuccess"
              multiple
            >
              <div class="upload-center">
                <Isvg icon-class="task-upload" />
                <span class="el-upload__text">拖拽或点击此处上传表格</span>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="loading" v-if="isShowExcelLoading"></div>
        <div class="success" v-if="isShowExcelSuccess"
          ><div class="sus">
            <div class="img">
              <img src="@/assets/icons/_tmp/correct.png" alt="" />
              <span>邀请成功，共邀请{{ inviteNums }}人</span>
            </div>
            <div class="btn" @click="$emit('close')">确定</div>
          </div></div
        >
        <div class="error" v-if="isShowExcelError">
          <div class="title">
            <i class="el-icon-warning-outline"></i>
            <p>错误提示</p>
          </div>
          <div class="error-main">
            <div class="main-wrapper" v-for="(item, index) in errorData" :key="index">
              <p>第{{ item.row }}行,{{ item.msg }}</p>
            </div>
          </div>
          <div class="error-btn" @click="reloadHandle">重新上传</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getToken } from '@/utils/auth';
  import qs from 'qs';
  export default {
    name: 'inviteDialog',
    props: {
      taskId: {
        type: [String, Number]
      },
      info: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        activeIndex: 0,
        headers: { Authorization: `Bearer ${getToken()}` },
        inviteList: [
          {
            mobile: '',
            name: '',
            price: '',
            mobileBlur: false,
            nameBlur: false,
            priceBlur: true,
            errorInfo: ''
          }
        ],
        inviteNums: '',
        errorData: {},
        isShowInputError: false,
        isShowInputSuccess: false,
        isShowInputLoading: false,
        isShowInput: true,
        disabled: true,
        isShowExcel: true,
        isShowExcelLoading: false,
        isShowExcelSuccess: false,
        isShowExcelError: false,
        dialogVisible: true,
        isFixed: false,
        fixedPrice: ''
      };
    },
    watch: {
      inviteList: {
        handler(val) {
          val.every((item) => item.mobile && item.name && item.price && !item.errorInfo)
            ? (this.disabled = false)
            : (this.disabled = true);
        },
        deep: true
      },
      info: {
        handler(val) {
          this.isFixed = val.min_price === val.max_price;
          this.fixedPrice = val.max_price.replace(/,/g, '');
          if (this.isFixed) {
            this.inviteList[0].price = this.fixedPrice;
          }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      beforeAvatarUpload(file) {
        this.isShowExcelLoading = true;
        this.isShowExcel = false;
        let fileName = file.name;
        let pos = fileName.lastIndexOf('.');
        let lastName = fileName.substring(pos, fileName.length);
        // 可以上传的类型
        let fileExts = ['.xls', '.xlsx'];
        if (!this.$store.getters.inArray(lastName.toLowerCase(), fileExts)) {
          this.$message.error('文件必须为.xls .xlsx类型');
          this.isShowExcelLoading = false;
          return false;
        }
      },
      uploadAttrSuccess(res, file) {
        if (res.statusCode == 200) {
          if (res.data.error === 1) {
            this.errorData = res.data.msgArray;
            this.isShowExcelLoading = false;
            this.isShowExcelError = true;
          } else {
            this.isShowExcelLoading = false;
            this.isShowExcelSuccess = true;
            this.inviteNums = res.data.success_count;
          }
        } else {
          this.isShowExcelLoading = false;
          this.isShowExcel = true;
          this.$notify({
            type: 'error',
            title: '文件上传通知',
            message: res.message
          });
        }
      },
      rewriteHandle() {
        this.isShowInput = true;
        this.isShowInputError = false;
      },
      reloadHandle() {
        this.isShowExcel = true;
        this.isShowExcelError = false;
      },
      changeTabHandle(val) {
        this.activeIndex = val;
      },
      deleteInviteListItemHandle(index) {
        this.inviteList.splice(index, 1);
      },
      addInviteListItemHandle() {
        const _tmp = {
          name: '',
          mobile: '',
          price: '',
          mobileBlur: false,
          nameBlur: false,
          priceBlur: true,
          errorInfo: ''
        };
        this.isFixed && (_tmp.price = this.fixedPrice);
        this.inviteList.push(_tmp);
        setTimeout(() => {
          this.$refs.row.scrollTop = 99999;
        }, 100);
      },
      cancelHandle() {
        this.$emit('close');
      },
      confirmHandle() {
        //
        if (this.disabled) return;
        this.isShowInput = false;
        this.isShowInputLoading = true;
        const data = [];
        this.inviteList.map((item) =>
          data.push({
            mobile: item.mobile,
            name: item.name,
            price: item.price
          })
        );
        const postData = {
          task_id: this.taskId,
          data: data
        };
        this.$Http
          .post({
            url: '/api/task/bid/invite/batch?',
            params: qs.stringify(postData)
          })
          .then((res) => {
            this.isShowInputLoading = false;
            if(res) {
              if (res.statusCode === 200 && res.data.error === 1) {
                this.errorData = res.data.msgArray;

                this.isShowInputError = true;
              }
              if (res.statusCode === 200 && !res.data.error) {
                this.isShowInputSuccess = true;
                this.inviteNums = res.data.success_count;
              }
            } else {
              this.isShowInput = true;
            }
          });
      },
      jumpUrl(url) {
        window.open(url, '_blank');
      },
      priceInputHandle(value, index) {
        const min_price = this.info.min_price.replace(/,/g, '');
        const max_price = this.info.max_price.replace(/,/g, '');
        if (+min_price <= +value && +value <= +max_price) {
          this.inviteList[index].errorInfo = '';
        } else {
          this.inviteList[index].errorInfo = `邀标价范围(${min_price}-${max_price})元`;
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .wrapper {
    .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      color: #575757;
      text-align: center;
    }
    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .invite-mobile,
      .excel-invite {
        .pointer;
        width: 200px;
        flex: 1;
        height: 44px;
        border-radius: 8px 8px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f8f8;
        .tit {
          font-size: 14px;
          font-weight: bold;
          line-height: 24px;
          color: #575757;
        }
        .special {
          margin-left: 4px;
          width: 64px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
          border-radius: 10px;
          background: #ededed;
          color: #575757;
        }
      }
      .active-tab {
        background: #e9f5ff;
        .special {
          background: #00b4ff;
          color: #fff;
        }
      }
    }
    .mobile-content {
      margin-top: 2px;
      height: 450px;
      overflow: hidden;
      .loading {
        width: 64px;
        margin: 0 auto;
        margin-top: 60px;
        height: 64px;
        background: url('~@/assets/icons/_tmp/loading.png');
        background-size: cover;
        animation: rotate 1s infinite;
        @keyframes rotate {
          from {
            transform: rotate(90deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      .success {
        padding-top: 60px;
        .sus {
          text-align: center;
          .img {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 64px;
              height: 64px;
            }
            span {
              margin-top: 20px;
            }
          }
          .btn {
            .pointer;
            margin: auto;
            width: 180px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            background: #00b4ff;
            margin-top: 122px;
            color: #fff;
          }
        }
      }
      .error {
        .title {
          display: flex;
          align-items: center;
          height: 36px;
          border-radius: 4px;
          background: #e9f5ff;
          .el-icon-warning-outline {
            color: #ff008e;
            font-size: 16px;
            margin-left: 19px;
          }
          p {
            font-size: 14px;
            line-height: 14px;
            color: #ff008e;
            margin-left: 17px;
          }
        }
        .error-main {
          height: 300px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 11px;
            height: 11px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #d8d8d8;
            border: 2px solid transparent;
            background-clip: padding-box;
          }
          .main-wrapper {
            .relative;
            .border-bottom-1px(1px);
            padding: 10px 19px;
            font-size: 14px;
          }
        }
        .error-btn {
          width: 180px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          margin: 0 auto;
          margin-top: 40px;
          .pointer;
          border-radius: 20px;
          background: #00b4ff;
        }
      }
      .t-head {
        display: flex;
        align-items: center;
        height: 40px;
        background: #e9f5ff;
        & > div {
          height: 100%;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding-left: 4px;
          .relative;
          .border-right-1px-color(1px,#ffffff,14px,12px);
        }
        .id {
          width: 7.5%;
        }
        .mobile {
          width: 27.5%;
        }
        .name {
          width: 20%;
        }
        .bid-price {
          width: 25%;
        }
        .operation {
          flex: 1;
        }
      }
      .t-body {
        height: 300px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .row {
          padding: 10px 0;
          .relative;
          .border-bottom-1px(1px);
          .wrap {
            display: flex;
            & > div {
              height: 24px;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding-left: 4px;

              input {
                width: 90%;
                //background-color: #f8f8f8;
                border: 1px solid #cccccc;
                height: 100%;
                font-size: 14px;
                padding:5px;
                font-size:12px;
              }
            }
            .has-value input {
              background-color: #fff;
            }
            .id {
              text-align: center;
              width: 7.5%;
            }
            .mobile {
              width: 27.5%;
            }
            .name {
              width: 20%;
            }
            .bid-price {
              width: 25%;
            }
            .operation {
              flex: 1;
              align-items: center;
              .relative;
              i {
                position: absolute;
                .pointer;
                font-size: 16px;

                &:first-of-type {
                  left: 20px;
                }
              }
              .el-icon-circle-plus-outline {
                color: #00b4ff;
                right: 20px;
              }
            }
          }
          .error-info {
            color: red;
            font-size: 14px;
            padding: 4px 40px;
          }
        }
      }
      .btn-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        padding:0 20px;
        div {
          .pointer;
          width: 180px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 20px;
          box-sizing: border-box;
          font-size: 14px;
          &[class='cancel'] {
            border: 1px solid #00b4ff;
            color: #00b4ff;
          }
          &[class='confirm'] {
            background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
            color: #ffffff;
          }
        }
        .disabled {
          background-color: #FFFFFF !important;
          border: 1px solid #dcdcdc;
          cursor: no-drop;
        }
      }
    }
    .excel {
      .template {
        height: 68px;
        background: #e9f5ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .t-left {
          font-size: 12px;
          font-weight: normal;
          line-height: 24px;
          color: #575757;
        }
        .t-right {
          .downLoad {
            cursor: pointer;
            width: 120px;
            height: 32px;
            border-radius: 20px;
            background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              background: url('~@/assets/icons/_tmp/caps.png');
              display: block;
              width: 16px;
              height: 16px;
              background-size: contain;
            }
            p {
              margin-left: 4px;
            }
          }
        }
      }
      .drag {
        //height: 120px;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //border: 1px dashed #dcdddd;
        margin-top: 20px;
        /deep/ .el-upload {
          display: block;
          .el-upload-dragger {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .pointer;
        .el-icon-circle-plus-outline {
          color: #00b4ff;
          font-size: 16px;
        }
        span {
          margin-left: 8px;
          font-size: 14px;
        }
        input {
          display: none;
        }
      }
    }
  }
</style>
