<template>
  <div class="time-check">
    <el-dialog title="工作开始时间设定" :visible="isShow" width="480px" @close="$emit('close')">
      <div class="wrapper">
        <div class="im" :class="{ active: radio === 'im' }">
          <el-radio v-model="radio" label="im" />
          <div class="label">
            <p @click="radio = 'im'">立即开始工作</p>
            <span>不可取消</span>
          </div>
        </div>
        <div class="time" :class="{ active: radio === 'time' }">
          <el-radio v-model="radio" label="time" />
          <div class="label">
            <p @click="radio = 'time'">设定开始时间</p>
            <div class="check">
              <div class="date">
                <span>日期</span>
                <el-date-picker
                    size="mini"
                    v-model="time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy年 M月 d日 H时"
                    type="date"
                    placeholder="选择日期"
                />
              </div>
              <div class="time">
                <span>时间</span>
                <el-time-picker
                    size="mini"
                    v-model="time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="H时 M分 S秒"
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    placeholder="任意时间点"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-group">
          <div class="cancel" @click="$emit('close')">取消</div>
          <div class="confirm" @click="startPeriod">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'timeDialog.vue',
    props: {
      isShow: {
        type: Boolean,
        default: false
      },
      period_id: {
        type: [String, Number],
        default: ''
      },
      task_id: {
        type: [String, Number],
        default: ''
      },
      type: {
        type: String,
        default: 'task'
      }
    },
    data() {
      return {
        radio: 'im',
        time: ''
      };
    },
    watch: {},
    methods: {
      startPeriod() {
        // 开启加载
        // const loading = this.$loading({
        //   lock: true,
        //   text: '正在开始工作...',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)'
        // });
        const url =
          this.type === 'task' ? '/api/uc/mini/task/start' : '/api/uc/mini/task/period/start';
        let params = {};

        if (this.radio === 'im') {
          params = {
            period_id: this.period_id
          };
          params =
            this.type === 'task'
              ? { task_id: this.task_id }
              : {
                  period_id: this.period_id
                };
        } else {
          if (this.type === 'task') {
            params = {
              task_id: this.task_id,
              auto_start: 1,
              auto_start_time: this.time
            };
          } else {
            params = {
              period_id: this.period_id,
              auto_start: 1,
              auto_start_time: this.time
            };
          }
        }
        this.$Http
          .post({
            url,
            params
          })
          .then((res) => {
            res && res.statusCode === 200 &&
              this.$emit('close') &&location.reload();
          });
      }
    }
  };
</script>

<style lang="less" scoped>
  /deep/ .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  /deep/ .el-radio {
    display: flex;
    .el-radio__label {
      display: none;
    }
  }
  /deep/ .el-date-editor {
    width: 160px !important;
  }
  .time-check {
    .wrapper {
      .im {
        display: flex;
        align-items: baseline;
        padding: 10px 20px;
        margin-bottom: 20px;
        .label {
          p {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: #575757;
          }
          span {
            margin-top: 8px;
            color: #9c9c9c;
            display: block;
          }
        }
      }
      .time {
        display: flex;
        align-items: baseline;
        padding: 10px 20px;
        .label {
          p {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: #575757;
            &:first-of-type {
              .pointer;
            }
          }
          .check {
            margin-top: 10px;
            display: flex;
            align-items: center;
            .date,
            .time {
              display: flex;
              align-items: center;
              span {
                flex-shrink: 0;
                margin-right: 6px;
              }
            }
          }
        }
      }
      .active {
        border-radius: 8px;
        background: #f4faff;
      }
      .btn-group {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          .pointer;
          width: 180px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 20px;
        }
        div:last-of-type {
          margin-left: 10px;
        }
        .cancel {
          border: 1px solid #dcdddd;
        }
        .confirm {
          background: linear-gradient(270deg, #00b4ff 0%, #00b4ff 0%, #1dd0d5 100%, #1dd0d5 100%);
          color: #fff;
        }
      }
    }
  }
</style>
