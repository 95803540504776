import { render, staticRenderFns } from "./baseinfoWrap.vue?vue&type=template&id=615709ed&scoped=true&"
import script from "./baseinfoWrap.vue?vue&type=script&lang=js&"
export * from "./baseinfoWrap.vue?vue&type=script&lang=js&"
import style0 from "./baseinfoWrap.vue?vue&type=style&index=0&id=615709ed&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615709ed",
  null
  
)

export default component.exports